<template>
  <div class="content-wrapper wrapper">
    <div class="wrapper">
      <div class="d-flex justify-space-between">
        <h1 class="pageHeader">
          {{ $t('label.membersInPlan') }}
        </h1>
      </div>
      {{ myPackageMemberListObj }}
      <!-- Payment Plan Content Start: -->
      <div class="my-10">
        <v-btn class="tabBtn">{{ $t(`label.account`) }} {{ '(1)' }}</v-btn>

        <div class="my-10">
          <span>(2)</span>
          <div class="d-flex align-center pb-5 text-capitalize">
            <div class="pr-3 cursor-pointer">{{ $t('label.addMember') }}</div>
            <span class="pr-3">|</span>

            <div
              class="pr-3 cursor-pointer"
              @click="clickSelect = !clickSelect"
              v-text="clickSelect ? $t(`label.select`) : 'X'"
            ></div>
            <div v-if="!clickSelect">
              <span class="pr-3">|</span>

              <!-- v-model="selectAllInbox" -->
              <input type="checkbox" id="selectAll" hidden />
              <label class="pr-3 cursor-pointer" for="selectAll">
                {{ $t(`label.selectAll`) }}</label
              >
              <span class="pr-3">|</span>
              <span class="pr-3 cursor-pointer"> {{ $t(`label.delete`) }}</span>
            </div>
          </div>
          <v-row no-gutters class="center listBg pa-6 mb-7" align="center">
            <div>
              <v-checkbox
                v-if="!clickSelect"
                on-icon="mdi-check-circle-outline"
                off-icon="mdi-checkbox-blank-circle-outline"
                class="pr-3 white--text"
                number
              ></v-checkbox>
            </div>
            <v-col cols="10">
              <v-row no-gutters>
                <v-col cols="1" class="d-flex align-center">
                  <v-avatar size="100">
                    <img
                      crossorigin="anonymous"
                      src="https://iamcasting.oss-ap-southeast-1.aliyuncs.com/web/logo-iamcasting-horizontal-white.png"
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="9" class="pl-10">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="12">
                      <div>Aileen</div>
                      <div>Aileen Tan</div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="3">
                      <div>Height</div>
                      <div>172cm</div>
                    </v-col>
                    <v-col cols="3">
                      <div>Race</div>
                      <div>Chinese</div>
                    </v-col>
                    <v-col cols="3">
                      <div>Language</div>
                      <div>English, Mandarin</div>
                    </v-col>
                    <v-col cols="3">
                      <div>Skills</div>
                      <div>Act, Performance</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" class="text-end">></v-col>
          </v-row>
        </div>
      </div>
      <!-- Payment Plan Content End: -->
    </div>
  </div>
</template>

<script>
  // import {
  //   PAYMENT_GET_MY_PACKAGE_MEMBER_LIST,
  //   PAYMENT_INITIAL_GET_MY_PACKAGE_MEMBER_LIST_STATE
  // } from '@/store/payment.module';

  export default {
    name: 'Payment',
    data: () => ({
      myPackageMemberListObj: [],
      clickSelect: true
    }),
    mounted() {
      this.initialGetMyPackageMemberList();
    },
    computed: {
      myPackageMemberListComplated() {
        return this.$store.state.payment.myPackageMemberList.complete;
      }
      // selectAllInbox: {
      //   get: function () {
      //     return this.itemsObj
      //       ? this.seletedInbox.length == this.itemsObj.length
      //       : false;
      //   },
      //   set: function (value) {
      //     console.log(value);
      //     var seletedInbox = [];
      //     if (value) {
      //       this.itemsObj.forEach(function (x) {
      //         seletedInbox.push(x.id);
      //       });
      //     }
      //     this.seletedInbox = seletedInbox;
      //   }
      // }
    },
    watch: {
      myPackageMemberListComplated() {
        let response = this.$store.state.payment.myPackageMemberList;
        if (response.complete) {
          this.importMyPackageMemberListObj(response);
        }
      }
    },
    methods: {
      importMyPackageMemberListObj(response) {
        this.myPackageMemberListObj = response.data;
        if (this.myPackageMemberListObj) {
          this.myPackageMemberListCompleteAction();
        }
      },
      myPackageMemberListCompleteAction() {
        this.initialGetMyPackageMemberListState();
      },
      initialGetMyPackageMemberList() {
        // this.$store.dispatch(PAYMENT_GET_MY_PACKAGE_MEMBER_LIST);
      },
      initialGetMyPackageMemberListState() {
        // this.$store.dispatch(PAYMENT_INITIAL_GET_MY_PACKAGE_MEMBER_LIST_STATE);
      },
      openMessageModal() {
        this.$openModal('messageModal', 'The feature will be available soon');
      }
    }
  };
</script>

<style>
  .v-icon.v-icon {
    color: rgba(255, 255, 255, 0.5);
  }
</style>
